<template>
  <div>
    <AmountIoListCard search="" />
  </div>
</template>
<script>
import AmountIoListCard from "../components/AmountIoListCard";
export default {
  components: { AmountIoListCard },
};
</script>
