<template>
  <v-tooltip bottom min-width="350">
    <template
      :allow-overflow="true"
      v-slot:activator="{ on, attrs }"
      :dark="true"
      color="#033"
    >
      <span v-bind="attrs" v-on="on">
        {{ _.sum(item.map((m) => m.amount)) | formatMoney }}
      </span>
    </template>
    <v-row
      v-for="(val, index) in _.orderBy(item, 'amount', 'desc')"
      :key="index"
    >
      <v-col cols="5" style=" padding:0px;"> {{ val.type }}</v-col>
      <v-col cols="2" style="color:yellow; padding:0px;">
        {{ val.count | formatMoney }}</v-col
      >
      <v-col cols="5" style="color:yellow; padding:0px;">
        {{ val.amount | formatMoney }}</v-col
      >
    </v-row>
  </v-tooltip>
</template>
<script>
export default {
  name: "AmountIoChipCard",
  props: {
    item: {
      type: Object,
      description: "item",
    },
  },
  data() {
    return {};
  },
};
</script>
