<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Amout Io</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      v-if="chipTransfer"
      hide-default-footer
      :options.sync="options"
      :items="chipTransfer.data"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.date="{ item }">
        <div>{{ new Date(item.date * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.priceTotal="{ item }">
        <div>{{ (item.chipPrice + item.goldPrice) | formatMoney }}</div>
      </template>
      <template v-slot:item.chipPrice="{ item }">
        <div>{{ item.chipPrice | formatMoney }}</div>
      </template>
      <template v-slot:item.goldPrice="{ item }">
        <div>{{ item.goldPrice | formatMoney }}</div>
      </template>
      <template v-slot:item.chipIn="{ item }">
        <amount-io-chip-card :item="item.chipIn" />
      </template>
      <template v-slot:item.chipOut="{ item }">
        <amount-io-chip-card :item="item.chipOut" />
      </template>
      <template v-slot:item.goldIn="{ item }">
        <amount-io-chip-card :item="item.goldIn" />
      </template>
      <template v-slot:item.goldOut="{ item }">
        <amount-io-chip-card :item="item.goldOut" />
      </template>
      <template v-slot:item.chipDiff="{ item }">
        {{
          (_.sum(item.chipIn.map((m) => m.amount)) -
            _.sum(item.chipOut.map((m) => m.amount)))
            | formatMoney
        }}
      </template>
      <template v-slot:item.goldDiff="{ item }">
        {{
          (_.sum(item.goldIn.map((m) => m.amount)) -
            _.sum(item.goldOut.map((m) => m.amount)))
            | formatMoney
        }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="chipTransfer.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import AmountIoChipCard from "./AmountIoChipCard.vue";
export default {
  components: { AmountIoChipCard },
  name: "TaxPayListCard",
  data() {
    return {
      dialog: false,
      currentPage: 1,
      chipTransfer: {},
      options: {},
      dates: [
        new Date(Date.now())
          .addDays(-365)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],

      menu: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "PriceTotal", value: "priceTotal" },
        { text: "ChipIn", value: "chipIn" },
        { text: "ChipOut", value: "chipOut" },
        { text: "ChipPrice", value: "chipPrice" },
        { text: "GoldIn", value: "goldIn" },
        { text: "GoldOut", value: "goldOut" },
        { text: "GoldPrice", value: "goldPrice" },
        { text: "Chip Difference", value: "chipDiff" },
        { text: "Gold Difference", value: "goldDiff" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.chipTransfer = await this.$store.dispatch("amountIo/getAll", {
        page: this.currentPage - 1,
        searchTypeSelect: this.searchTypeSelect,
        options: this.options,
        startDate: new Date(this.dates[0]).getTime() / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
      });
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
  },
};
</script>
